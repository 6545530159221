import * as Yup from "yup";
const moment = require("moment");
export const locTestSchema = Yup.object({
  fk_loc_id: Yup.string().required("Please Select the Location Name"),
  // fk_test_id: Yup.string().required("Please Select the Test Name"),
  fk_test_ids: Yup.array().min(1).required("Please select test"),
});
export const globalSettingSchema = Yup.object({
  gs_vat: Yup.string().min(2).max(255).required("Please Enter the Vat Value"),
  gs_vat_number: Yup.string()
    .min(2)
    .max(255)
    .required("Please Enter the Vat Number"),
  gs_vatType: Yup.string().required("Please Select the Vat Type"),
  fk_country_id: Yup.string().required("Please Select the Country Name"),
});
export const sampleCommentSchema = Yup.object({
  sample_comment: Yup.string()
    .min(2)
    .max(255)
    .required("Please Enter the Sample Comment"),
});
export const hospitalSchema = Yup.object({
  hos_name_en: Yup.string()
    .min(2)
    .max(255)
    .required("Please Enter the Hospital EN Name"),
  hos_name_ar: Yup.string()
    .min(2)
    .max(255)
    .required("Please Enter the Hospital AR Name"),

  hos_lat: Yup.string()
    .min(2)
    .max(30)
    .required("Please Enter the Hospital Latitude"),

  hos_long: Yup.string()
    .min(2)
    .max(30)
    .required("Please Enter the Hospital Longitude"),

  hos_vat_no: Yup.string()
    .matches(/^(3)([0-9]{15})$/g, "vat number is not valid")
    .required("Please Enter the Vat No")
    .required("vat no is required"),

  hos_hesn_no: Yup.string()
    .min(15)
    .max(15)
    .required("Please Enter the HESN No"),
});

export const testColorSchema = Yup.object({
  mclr_name: Yup.string()
    .min(2)
    .max(30)
    .required("Please Enter the Color Name"),
  mclr_hex_code: Yup.string()
    .min(2)
    .max(30)
    .required("Please Enter the Color Name"),
});

export const warehouseItemCategorySchema = Yup.object({
  wic_name: Yup.string()
    .min(2)
    .max(30)
    .required("Please Enter the Category Name"),
});

export const methodologySchema = Yup.object({
  methodology_name: Yup.string()
    .min(2)
    .max(30)
    .required("Please Enter the methodology name"),
});

export const clinicRefferalSchema = Yup.object({
  resv_ref_name: Yup.string()
    .min(2)
    .max(30)
    .required("Please Enter the Refferal Name"),
  resv_ref_phone: Yup.string()
    .min(2)
    .max(30)
    .required("Please Enter the phone number"),
  resv_ref_contact_address: Yup.string()
    .min(2)
    .max(30)
    .required("Please Enter the address"),
  resv_ref_discount_number: Yup.number().required(
    "Please Enter Clinic Refferal Discount"
  ),
});

export const clinicSchema = Yup.object({
  mc_name: Yup.string()
    .min(2)
    .max(255)
    .required("Please Enter the Clinic Name"),

  // mc_address: Yup.string()
  //   .min(2)
  //   .max(255)
  //   .required("Please Enter the Clinic Address"),
  // mc_postalCode: Yup.string()
  //   .min(2)
  //   .max(255)
  //   .required("Please Enter the Clinic Postal Code"),

  // mc_phone: Yup.string()
  //   .min(10)
  //   .max(10)
  //   .required("Please Enter the phone number"),

  // mc_fax: Yup.string()
  //   .min(2)
  //   .max(255)
  //   .required("Please Enter the Clinic Fax"),
  // mc_email: Yup.string()
  //   .min(2)
  //   .max(255)
  //   .required("Please Enter the Clinic Email"),
  // mc_vat: Yup.string()
  //   .min(1)
  //   .required("Please Enter the Clinic VAT"),
  // mc_discount: Yup.string()
  //   .min(1)
  //   .required("Please Enter the Clinic Discount"),
  // mc_website: Yup.string()
  //   .min(2)
  //   .max(255)
  //   .required("Please Enter the Clinic Website"),
  // fk_country_id: Yup.string()
  //   .min(1)
  //   .max(30)
  //   .required("Please Select Country"),
  // fk_city_id: Yup.string()
  //   .min(1)
  //   .max(30)
  //   .required("Please Select City"),
  // fk_state_id: Yup.string()
  //   .min(1)
  //   .max(30)
  //   .required("Please Select State"),
  // mc_latitude: Yup.string()
  //   .min(2)
  //   .max(30)
  //   .required("Please Enter the Clinic Lattitude"),
  // mc_longitude: Yup.string()
  //   .min(2)
  //   .max(30)
  //   .required("Please Enter the Clinic Longitude"),
  // mc_payment_type: Yup.string().when('mc_is_outsource', {
  //   is: true,
  //   then: Yup.string().required("Payment Mode Required"),
  //   otherwise: Yup.string().nullable()
  // }),
  // mc_interval: Yup.string().when(['mc_is_outsource', 'mc_payment_type'], {
  //   is: (isOutsource, paymentType) => isOutsource && paymentType === 'POSTPAID',
  //   then: Yup.string().when('mc_payment_type', {
  //     is: 'POSTPAID',
  //     then: Yup.string().required('Please Select Interval'),
  //     otherwise: Yup.string().nullable()
  //   }),
  //   otherwise: Yup.string().nullable()
  // })

  /* mc_payment_type: Yup.string().required("Payment Mode Required"),
  mc_interval: Yup.string().when('mc_payment_type', {
    is: 'POSTPAID',
    then: Yup.string().required('Please Select Interval'),
    otherwise: Yup.string().nullable()
  }), */
});
export const locationSchema = Yup.object({
  loc_name_en: Yup.string()
    .min(2)
    .max(255)
    .required("Please Enter the Location EN Name"),
  loc_name_ar: Yup.string()
    .min(2)
    .max(255)
    .required("Please Enter the Location AR Name"),
  loc_lat: Yup.string()
    .min(2)
    .max(255)
    .required("Please Enter the Location Latitude"),
  loc_long: Yup.string()
    .min(2)
    .max(255)
    .required("Please Enter the Location Longitude"),
  fk_hos_id: Yup.string().required("Please Select the Hospital Name"),
  fk_region_id: Yup.string().required("Please Select the Region Name"),
});
export const priceListSchema = Yup.object({
  price_name_en: Yup.string()
    .min(2)
    .max(255)
    .required("Please Enter Price Name (EN)"),
  price_name_ar: Yup.string()
    .min(2)
    .max(255)
    .required("Please Enter Price Name (AR)"),
  price_type: Yup.string().min(1).max(255).required("Please Select Type"),
});

export const sampleTypeSchema = Yup.object({
  sample_type_name_en: Yup.string()
    .min(2)
    .max(255)
    .required("Please Enter Sample Type Name (EN)"),
  sample_type_name_ar: Yup.string()
    .min(2)
    .max(255)
    .required("Please Enter Sample type Name (AR)"),
});
export const priceListDetailsSchema = Yup.object({
  tp_type: Yup.string().required("Please Select Type"),
  tp_price: Yup.number().min(1).max(99999).required("Please Enter Cost Price"),
  tp_selling_price: Yup.number()
    .min(1)
    .max(99999)
    .required("Please Enter Selling Price"),
});

export const shiftMasterSchema = Yup.object({
  shift_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Shift Master Name"),
    start_date: Yup.string().required("Start Date is required"),
    end_date: Yup.string()
    .required("End Date is required")
    .test(
      "is-greater",
      "End Date must be later than Start Date",
      function (value) {
        const { start_date } = this.parent;
        if (!start_date || !value) return true; // skip validation if either is empty
        return new Date(value) > new Date(start_date);
      }
    ),
});

export const countrySchema = Yup.object({
  country_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Country Name"),
  country_code: Yup.string().required("please Enter 3 character Country code"),
  country_isd_code: Yup.number()
    .min(1)
    .max(999)
    .required("please Enter the Country Calling code"),
});
export const userInfoSchema = Yup.object({
  user_first_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the First Name"),
  user_last_name: Yup.string()
    .min(2)
    .max(100)
    .required("please Enter the Last Name"),
  user_email: Yup.string().min(2).max(100).required("please Enter the email"),
  // user_mobile: Yup.number()
  //   .min(5)
  //   .max(15)
  //   .required("please Enter mobile number"),
  // user_signature: Yup.string()
  //   .min(2)
  //   .max(200)
  //   .required("please upload signature"),
});
export const genderSchema = Yup.object({
  gender_name_en: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Engllish gender"),
  gender_name_ar: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Arabic gender"),
});
export const outSourcingReportSchema = Yup.object({
  date_from: Yup.date().required("Please Enter the Date From"),
  date_to: Yup.date().required("Please Enter the Date To"),
  medical_complex: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter Medical Complex"),
  reservation_id: Yup.string().required("Please Enter Reservation Id"),
});
export const stateSchema = Yup.object({
  state_name_en: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter English State Name"),
  state_name_ar: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter Arabic State Name"),
  fk_country_id: Yup.string().required("Please select country"),
});
export const citySchema = Yup.object({
  city_name_en: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter English City Name"),
  city_name_ar: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter Arabic City Name"),
  fk_state_id: Yup.string().required("Please select state"),
});
export const labAnalyzerSchema = Yup.object({
  lab_analyzer_name_en: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter En Name"),
  lab_analyzer_name_ar: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter Ar Name"),
  lab_analyzer_type: Yup.string().min(2).max(40).required("Please choose Type"),
  fk_test_ids: Yup.array().min(1).required("Please select test"),
  fk_loc_ids: Yup.array().min(1).required("Please select Locations"),
  // fk_titem_ids: Yup.array().min(1).required("Please select item"),
});
export const commentsSchema = Yup.object({
  comment_text: Yup.string().min(2).max(255).required("Please Enter Text"),
  comment_type: Yup.string().min(2).max(40).required("Please choose type"),
  fk_test_id: Yup.string().required("Please select test"),
});
export const roleSchema = Yup.object({
  role_name_en: Yup.string()
    .min(2)
    .max(50)
    .required("Please Enter the Role Name Eng"),
  role_name_ar: Yup.string()
    .min(2)
    .max(50)
    .required("Please Enter the Role Name Arabic"),
});
export const unitSchema = Yup.object({
  unit_name: Yup.string().min(2).max(25).required("Please Enter the Unit Name"),
});
export const categorySchema = Yup.object({
  category_name_en: Yup.string()
    .min(2)
    .max(225)
    .required("Please Enter the Category EN Name"),
  category_name_ar: Yup.string()
    .min(2)
    .max(225)
    .required("Please Enter the Category AR Name"),
});
export const testSchema = Yup.object({
  test_name_ar: Yup.string()
    .min(2)
    .max(225)
    .required("Please Enter the Test Name(AR)"),
  test_name_en: Yup.string()
    .min(2)
    .max(525)
    .required("Please Enter the Test Name(EN)"),
  test_min_price: Yup.string().required("Please Enter Test Min Price"),
  test_code: Yup.string().required("Please Enter the Test Code"),
  test_smart_report_description_en: Yup.string().required(
    "Please Enter the Smart Report Description(EN)"
  ),
  test_smart_report_description_ar: Yup.string().required(
    "Please Enter the Smart Report Description(AR)"
  ),
  test_short_description_en: Yup.string().required(
    "Please Enter the Short Description(EN)"
  ),
  test_short_description_ar: Yup.string().required(
    "Please Enter the Short Description(AR)"
  ),
  fk_rr_id: Yup.string().required("Please Select the Reference Range"),
  fk_hos_id: Yup.string().required("Please Select the Hospital"),
  fk_category_id: Yup.string().required("Please Select the Category"),
  fk_sample_type_id: Yup.string().required("Please Select the Sample Type"),
  hos_price: Yup.number().required("Please Enter the Hospital Price"),
});

export const agenttypeSchema = Yup.object({
  agent_type_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Agent Type"),
});
export const labSchema = Yup.object({
  lab_name: Yup.string().min(2).max(25).required("Please Enter the Lab Name"),
  fk_location_id: Yup.string().required("Please Select the Location Name"),
  fk_city_id: Yup.string().required("Please Select the City Name"),
  fk_state_id: Yup.string().required("Please Select the State Name"),
  fk_country_id: Yup.string().required("Please Select the Country Name"),
});

export const packageSchema = Yup.object({
  package_name_en: Yup.string()
    .min(2)
    .max(225)
    .required("Please Enter the Package Name(EN)"),
  package_name_ar: Yup.string()
    .min(2)
    .max(225)
    .required("Please Enter the Package Name(AR)"),
  // package_short_description_en: Yup.string()
  //   .min(2)
  //   .max(225)
  //   .required("Please Enter the Package Short Desc(EN)"),
  // package_short_description_ar: Yup.string()
  //   .min(2)
  //   .max(225)
  //   .required("Please Enter the Package Short Desc(AR)"),
  // package_long_description_en: Yup.string()
  //   .min(2)
  //   .max(225)
  //   .required("Please Enter the Package Long Desc(EN)"),
  // package_long_description_ar: Yup.string()
  //   .min(2)
  //   .max(225)
  //   .required("Please Enter the Package Long Desc(AR)"),

  // fk_test_ids: Yup.string()
  // 	.required("Please Select the Test Name"),
  package_price: Yup.number().required("Please Enter the Package Price"),
  // package_sub_category: Yup.string().required(
  //   "Please Enter Package Sub-Category"
  // ),
});
export const subtestSchema = Yup.object({
  ttp_name_en: Yup.string()
    .min(2)
    .max(225)
    .required("Please Enter the Test-Parameter Name(AR)"),
  ttp_name_ar: Yup.string()
    .min(2)
    .max(525)
    .required("Please Enter the Test-Parameter Name(EN)"),
  // ttp_min_price: Yup.string().required("Please Enter Test-Parameter Min Price"),
  ttp_test_code: Yup.string().required("Please Enter the Test-Parameter Code"),
  // ttp_smart_report_description_en: Yup.string().required(
  //   "Please Enter the Test-Parameter Smart Report Description(EN)"
  // ),
  // ttp_smart_report_description_ar: Yup.string().required(
  //   "Please Enter the Test-Parameter Smart Report Description(AR)"
  // ),
  // ttp_short_description_en: Yup.string().required(
  //   "Please Enter the Test-Parameter Short Description(EN)"
  // ),
  // ttp_short_description_ar: Yup.string().required(
  //   "Please Enter the Test-Parameter Short Description(AR)"
  // ),
  // ttp_tips_description_en: Yup.string().required(
  //   "Please Enter the Test-Parameter Tips(EN)"
  // ),
  // ttp_tips_description_ar: Yup.string().required(
  //   "Please Enter the Test-Parameter Tips(AR)"
  // ),
  fk_rr_id: Yup.string().required("Please Select the Reference Range"),
  fk_test_id: Yup.string().required("Please Select the Test"),
  // ttp_hos_price: Yup.number().required(
  //   "Please Enter the Test-Parameter Hospital Price"
  // ),
});

export const uuserSchema = Yup.object({
  user_first_name: Yup.string()
    .min(3)
    .max(50)
    .required("Please Enter the Full Name"),
  // user_last_name: Yup.string()
  //   .min(3)
  //   .max(25)
  //   .required("Please Enter the Last Name"),
  // user_email: Yup.string().email().required("Please Enter the Email ID"),
  user_dob: Yup.date().required("Please Enter the DOB"),
  user_id_type: Yup.string().required("Please Select the ID Type"),
  user_id_no: Yup.string()
    .required("Please Enter the ID No")
    .when(["user_id_type"], (user_id_type, schema) => {
      let text;
      switch (user_id_type) {
        case "Iqama":
          text = schema
            .matches(
              /^(2)([0-9]{9})$/g,
              "Iqma number should be 10 digit and start with 2"
            )
            .required("Please Enter the Iqama No");
          break;
        case "National ID":
          text = schema
            .matches(
              /^(1)([0-9]{9})$/g,
              "National ID number should be 10 digit and start with 1"
            )
            .required("Please Enter the National ID No");
          break;
        case "BorderNo":
          text = schema
            .matches(
              /^[3,4,6]([0-9]{9})$/g,
              "BorderNo number should be 10 digit and start with 3,4,6"
            )
            .required("Please Enter the BorderNo No");
          break;
        case "Gulf ID":
          text = schema
            .min(8, "Gulf Id should be 8 to 10 Characters")
            .max(10, "Gulf Id should be 8 to 10 Characters")
            .required("Gulf Id should be 8 to 10 Characters");
          break;
        default:
          text = schema.min(1);
      }
      return text;
    }),
  // user_passport_no: Yup.string().required("Please Enter the Passport No"),
  // user_reasion_test: Yup.string().required(
  //   "Please Enter the Reasion Of Testing"
  // ),
  // user_ref_mrn_no: Yup.string().required("Please Enter the Ref. MRN No"),
  fk_gender_id: Yup.string().required("Please Select Gender"),
  fk_country_id: Yup.string().required("Please Select Nationality"),
  user_mobile: Yup.string().required("Mobile Number is Invalid"),
  // relationship: Yup.string().required("Please Select Relationship"),
});

export const uuserSchema2 = Yup.object({
  user_first_name: Yup.string()
    .min(3)
    .max(50)
    .required("Please Enter the Full Name"),
  // user_last_name: Yup.string()
  //   .min(3)
  //   .max(25)
  //   .required("Please Enter the Last Name"),
  // user_email: Yup.string().email().required("Please Enter the Email ID"),
  user_dob: Yup.date().required("Please Enter the DOB"),
  // user_id_type: Yup.string().required("Please Select the ID Type"),
  //   user_id_no: Yup.string().required("Please Enter the ID No")
  //     .when(['user_id_type'], (user_id_type, schema) => {
  //       let text;
  //       switch (user_id_type) {
  //         case 'Iqama':
  //           text = schema.matches(/^(2)([0-9]{9})$/g, 'Iqma number should be 10 digit and start with 2').required("Please Enter the Iqama No");
  //           break;
  //         case 'National ID':
  //           text = schema.matches(/^(1)([0-9]{9})$/g, 'National ID number should be 10 digit and start with 1').required("Please Enter the National ID No");
  //           break;
  //         case 'BorderNo':
  //           text = schema.matches(/^[3,4,6]([0-9]{9})$/g, 'BorderNo number should be 10 digit and start with 3,4,6').required("Please Enter the BorderNo No");
  //           break;
  //         case 'Gulf ID':
  //           text = schema.min(8, 'Gulf Id should be 8 to 10 Characters').max(10, 'Gulf Id should be 8 to 10 Characters').required('Gulf Id should be 8 to 10 Characters');
  //           break;
  //         default:
  //           text = schema.min(20);
  //       }
  //     return text;
  // }),
  // user_passport_no: Yup.string().required("Please Enter the Passport No"),
  // user_reasion_test: Yup.string().required(
  //   "Please Enter the Reasion Of Testing"
  // ),
  // user_ref_mrn_no: Yup.string().required("Please Enter the Ref. MRN No"),
  fk_gender_id: Yup.string().required("Please Select Gender"),
  // fk_country_id: Yup.string().required("Please Select Nationality"),
  // user_mobile: Yup.string().required("Mobile Number is Invalid")
  // relationship: Yup.string().required("Please Select Relationship"),
});

export const patientDataSchemaOC = Yup.object({
  user_first_name: Yup.string()
    .min(3)
    .max(50)
    .required("Please Enter the Full Name"),
  user_age: Yup.number()
    .typeError("Age must be a number")
    .required("Please enter age")
    .min(0, "Age must be at least 0")
    .max(110, "Age must be 110 or less"),
  insu_membership_id: Yup.string().required("Please enter Membership ID"),
  fk_gender_id: Yup.string().required("Please enter gender"),
  insu_user_mobile: Yup.string().required("Please enter Mobile Number"),
  fk_country_id: Yup.string().required("Please Select Nationality"),
});

export const userPatientSchema = Yup.object({
  user_first_name: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the Full Name"),
  // user_email: Yup.string().email().required("Please Enter the Email ID"),
  user_dob: Yup.date().required("Please Enter the DOB"),
  user_id_type: Yup.string().required("Please Select the ID Type"),
  user_id_no: Yup.string()
    .required("Please Enter the ID No")
    .when(["user_id_type"], (user_id_type, schema) => {
      let text;
      switch (user_id_type) {
        case "Iqama":
          text = schema
            .matches(
              /^(2)([0-9]{9})$/g,
              "Iqma number should be 10 digit and start with 2"
            )
            .required("Please Enter the Iqama No");
          break;
        case "National ID":
          text = schema
            .matches(
              /^(1)([0-9]{9})$/g,
              "National ID number should be 10 digit and start with 1"
            )
            .required("Please Enter the National ID No");
          break;
        case "BorderNo":
          text = schema
            .matches(
              /^[3,4,6]([0-9]{9})$/g,
              "BorderNo number should be 10 digit and start with 3,4,6"
            )
            .required("Please Enter the BorderNo No");
          break;
        case "Gulf ID":
          text = schema
            .min(8, "Gulf Id should be 8 to 10 Characters")
            .max(10, "Gulf Id should be 8 to 10 Characters")
            .required("Gulf Id should be 8 to 10 Characters");
          break;
        default:
          text = schema.min(1);
      }
      return text;
    }),
  // user_passport_no: Yup.string().required("Please Enter the Passport No"),
  fk_gender_id: Yup.number().required("Please Select Gender"),
  fk_country_id: Yup.number().required("Please Select Nationality"),
  user_mobile: Yup.string().required("Mobile Number is Invalid"),
  // relationship: Yup.string().required("Please Select Relationship"),
});

export const refRulesSchema = Yup.object({
  /* age_from: Yup.number().min(1).max(150).required("Please Enter Age From"),
  age_to: Yup.number().min(1).max(150).required("Please Enter Age To"), */
  normal_min: Yup.number().min(0).required("Please Enter Normal MIN"),
  normal_max: Yup.number().min(0).required("Please Enter Normal Max"),
  gender: Yup.string().required("Please choose gender"),
});

// export const locationSchema = Yup.object({
//   location_name: Yup.string()
//     .min(2)
//     .max(25)
//     .required("Please Enter the location name"),s
//   location_code: Yup.string()
//     .min(6)
//     .max(6)
//     .required("please Enter the location code"),
// });

export const MedicineSchema = Yup.object({
  generic_name: Yup.string().required("Please Enter the Generic name"),

  strength: Yup.string().required("Please Enter the strength "),

  formulation: Yup.string().required("Please Enter the formulation "),

  route_of_administration: Yup.string().required(
    "Please Enter the route of administration "
  ),

  frequency: Yup.string().required("Please Enter the frequency "),

  medicine_time: Yup.string().required("Please Enter the time "),

  start_date: Yup.date().required("Please Enter the start date "),

  duration: Yup.string().required("Please Enter the duration "),

  instructions: Yup.string().required("Please Enter the instructions "),
});

export const doctorSchema = Yup.object({
  first_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the first name"),

  last_name: Yup.string().min(2).max(25).required("Please Enter the last name"),

  email: Yup.string().email().required("Please Enter the email address"),

  phone_number: Yup.string()
    .min(10)
    .max(10)
    .required("Please Enter the phone number"),

  password: Yup.string().min(6).required("Please Enter the password"),

  appointment_cost: Yup.number().required("Please Enter the appointment cost"),

  is_follow_up_cost: Yup.number().required("Please Enter follow up cost"),

  Confirm_Password: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Password must match"),
});
export const patientsSchema = Yup.object({
  patient_first_name: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the First name"),
  patient_last_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the last name"),

  patient_referral_code: Yup.string().required("Please Enter a Referral"),
  patient_mobile: Yup.string()
    .min(10)
    .max(10)
    .required("Please Enter the Mobile Number"),
  patient_email: Yup.string().email().required("Please Enter the Email ID"),
  patient_location_id: Yup.string().required("Please Select the Location ID"),
  patient_gender: Yup.string().required("Please Select the Gender"),
  dob: Yup.string().required("Please Enter the date of birth"),
  // address: Yup.string().min(3).required("Please Enter the address"),
});

// export const patientsSchema = Yup.object({
//   firstName: Yup.string()
//     .min(3)
//     .max(25)
//     .required("Please Enter the First name"),
//   lastName: Yup.string().min(2).max(25).required("Please Enter the last name"),
//   fatherFirstName: Yup.string()
//     .min(3)
//     .max(25)
//     .required("Please Enter the father first name"),
//   fatherLastName: Yup.string()
//     .min(3)
//     .max(25)
//     .required("Please Enter the father last name"),
//   motherFirstName: Yup.string()
//     .min(3)
//     .max(25)
//     .required("Please Enter the mother first name"),
//   motherLastName: Yup.string()
//     .min(3)
//     .max(25)
//     .required("Please Enter the mother last name"),
//   guardiansFirstName: Yup.string()
//     .min(3)
//     .max(25)
//     .required("Please Enter the gaurdians first name"),
//   guardiansLastName: Yup.string()
//     .min(3)
//     .max(25)
//     .required("Please Enter the gaurdians last name"),
//   referral_id: Yup.string().required("Please select a referral"),
//   phoneNo: Yup.string()
//     .min(10)
//     .max(10)
//     .required("Please Enter the phone number"),
//   dob: Yup.string().required("Please Enter the date of birth"),
//   address: Yup.string().min(3).required("Please Enter the address"),
// });

export const userSchema = Yup.object({
  user_first_name: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the First Name"),
  user_last_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Last Name"),
  user_email: Yup.string().email().required("Please Enter the Email address"),
  user_mobile: Yup.string()
    .min(10)
    .max(10)
    .required("Please Enter the Mobile Number"),
  user_password: Yup.string().min(6).required("Please Enter the Password"),
  // conf_password: Yup.string()
  // 	.required()
  // 	.oneOf([Yup.ref("user_password"), null], "Password must match"),
  // user_location_id: Yup.string().required("Please Enter the Location ID"),
  user_role: Yup.string().required("Please Enter the Role"),
});

export const scheduleSchema = Yup.object({
  name: Yup.string().min(3).max(25).required("Please Enter the name"),
  start_date: Yup.date().required("Please Enter the start date"),
  end_date: Yup.date()
    .min(Yup.ref("start_date"), "End date must be greater than start date")
    .required("Please Enter the end date"),
  location_id: Yup.string().required("Please select the location"),
  frequency: Yup.string().required("Please Enter the frequency"),
});

export const availabilitySchema = Yup.object({
  sunday: Yup.boolean(),
  sun_start: Yup.string().when("sunday", {
    is: true,
    then: Yup.string().required("Please Enter the start time"),
  }),
  sun_end: Yup.string().when("sunday", {
    is: true,
    then: Yup.string()
      .required("end time cannot be empty")
      .test("is-greater", "end time should be greater", function (value) {
        const { sun_start } = this.parent;
        return moment(value, "HH:mm a").isSameOrAfter(
          moment(sun_start, "HH:mm a")
        );
      }),
  }),

  monday: Yup.boolean(),
  mon_start: Yup.string().when("monday", {
    is: true,
    then: Yup.string().required("Please Enter the start time"),
  }),
  mon_end: Yup.string().when("monday", {
    is: true,
    then: Yup.string()
      .required("end time cannot be empty")
      .test("is-greater", "end time should be greater", function (value) {
        const { mon_start } = this.parent;
        return moment(value, "HH:mm a").isSameOrAfter(
          moment(mon_start, "HH:mm a")
        );
      }),
  }),

  tuesday: Yup.boolean(),
  tue_start: Yup.string().when("tuesday", {
    is: true,
    then: Yup.string().required("Please Enter the start time"),
  }),
  tue_end: Yup.string().when("tuesday", {
    is: true,
    then: Yup.string()
      .required("end time cannot be empty")
      .test("is-greater", "end time should be greater", function (value) {
        const { tue_start } = this.parent;
        return moment(value, "HH:mm a").isSameOrAfter(
          moment(tue_start, "HH:mm a")
        );
      }),
  }),

  wednesday: Yup.boolean(),
  wed_start: Yup.string().when("wednesday", {
    is: true,
    then: Yup.string().required("Please Enter the start time"),
  }),
  wed_end: Yup.string().when("wednesday", {
    is: true,
    then: Yup.string()
      .required("end time cannot be empty")
      .test("is-greater", "end time should be greater", function (value) {
        const { wed_start } = this.parent;
        return moment(value, "HH:mm a").isSameOrAfter(
          moment(wed_start, "HH:mm a")
        );
      }),
  }),

  thursday: Yup.boolean(),
  thu_start: Yup.string().when("thursday", {
    is: true,
    then: Yup.string().required("Please Enter the start time"),
  }),
  thu_end: Yup.string().when("thursday", {
    is: true,
    then: Yup.string()
      .required("end time cannot be empty")
      .test("is-greater", "end time should be greater", function (value) {
        const { thu_start } = this.parent;
        return moment(value, "HH:mm a").isSameOrAfter(
          moment(thu_start, "HH:mm a")
        );
      }),
  }),

  friday: Yup.boolean(),
  fri_start: Yup.string().when("friday", {
    is: true,
    then: Yup.string().required("Please Enter the start time"),
  }),
  fri_end: Yup.string().when("friday", {
    is: true,
    then: Yup.string()
      .required("end time cannot be empty")
      .test("is-greater", "end time should be greater", function (value) {
        const { fri_start } = this.parent;
        return moment(value, "HH:mm a").isSameOrAfter(
          moment(fri_start, "HH:mm a")
        );
      }),
  }),

  saturday: Yup.boolean(),
  sat_start: Yup.string().when("saturday", {
    is: true,
    then: Yup.string().required("Please Enter the start time"),
  }),
  sat_end: Yup.string().when("saturday", {
    is: true,
    then: Yup.string()
      .required("end time cannot be empty")
      .test("is-greater", "end time should be greater", function (value) {
        const { sat_start } = this.parent;
        return moment(value, "HH:mm a").isSameOrAfter(
          moment(sat_start, "HH:mm a")
        );
      }),
  }),
});

export const overrideSchema = Yup.object({
  available: Yup.boolean(),
  date: Yup.string()
    .required("please Enter a date")
    .when("check", {
      is: true,
      then: Yup.string().required("please Enter a date"),
    }),
  from_time: Yup.string().required(),
  to_time: Yup.string()
    .required("this shouldn't be empty")
    .test("is-greater", "end time should be greater", function (value) {
      const { from_time } = this.parent;
      return moment(value, "HH:mm a").isSameOrAfter(
        moment(from_time, "HH:mm a")
      );
    }),
});

export const AppointmentSchema = Yup.object().shape({
  type: Yup.string().oneOf(["HOME_VISIT", "NORMAL"]).required(),
  title: Yup.string().required("TItle is required"),
  location_id: Yup.number().required("Location is required"),
  patient_id: Yup.number().required("Patient is required"),
  amount: Yup.number().required("Amount is required"),
});

export const UpdateAppointmentSchema = Yup.object().shape({
  type: Yup.string().oneOf(["OFFLINE", "ONLINE"]).required(),
  location_id: Yup.string().when("type", {
    is: "OFFLINE",
    then: Yup.string().required("Location is required"),
  }),
  app_patient_id: Yup.string().required("Patient is required"),
  // doctorId: Yup.string().required('Doctor is required'),
  app_date: Yup.date().required("Appointment Date is required"),
  app_time: Yup.string().required("Appointment Time is required"),
  // payment_type: Yup.string().oneOf(['FULL', 'ADVANCE']).required(),
  // advance_payment_amount: Yup.string().when("payment_type", {
  //   is: "ADVANCE",
  //   then: Yup.string().required('Advance Payment Amount is required')
  // }),
  isFollowUp: Yup.boolean().optional(),
  // cost: Yup.number().required("Cost is required"),
});

export const ReferralSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  type: Yup.string().required("Type is required"),
});
export const DepartmentSchema = Yup.object({
  name: Yup.string().required("Name is required"),
});
export const GoalSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  department_id: Yup.string().required("Select a Department"),
});
export const CategorySchema = Yup.object({
  name: Yup.string().required("Name is required"),
});
export const ageGroupSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  category_id: Yup.string().required("Select a Category"),
});
export const domainSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  age_group_id: Yup.string().required("Select a Age Group"),
});

export const textnewSchema = Yup.object({
  test_name: Yup.string().min(2).max(25).required("Please Enter the name"),
  Min_Max: Yup.string()
    .min(1)
    .max(5)
    .required("please Enter the Min-Max value"),
  description: Yup.string()
    .min(2)
    .max(25)
    .required("please Enter the description"),
  Unit: Yup.string().min(2).max(25).required("please Enter the Unit"),
  test_location: Yup.string().required("Please Select the Location Name"),
});

export const invoiceSchema = Yup.object({
  from_content: Yup.string().required("Please Enter the From Content"),
  bill_to: Yup.string().required("Please Enter the To"),
  ship_to: Yup.string().required("Please Enter the Ship to"),
  invoiceno: Yup.number().required("Please Enter the Invoice Number"),
  invoicename: Yup.string().required("Please Enter the Invoice Name"),
  paymenttearm: Yup.string().required("Please Enter the Payment Terms"),
  duedate: Yup.string().required("Due Date is required"),
  ponumber: Yup.number().required("Please Enter PO number"),
  note: Yup.string().required("Please Enter Note"),
  term: Yup.string().required("Please Enter Terms"),
  subtotal: Yup.number().required("Please Enter Sub Total"),
  balance: Yup.number().required("Please Enter Balance"),
  tax: Yup.number().required("Please Enter Tax"),
  total: Yup.number().required("Please Enter Total"),
  amount: Yup.number().required("Please Enter Amount"),
  invoicenumber: Yup.number().required("Please Enter Invoice Number"),
  date: Yup.string().required("Appointment Date is required"),
});

export const statusSchema = Yup.object({
  title: Yup.string().required("Name is required"),
  description: Yup.string().required("Enter Description"),
});

export const sampleSchema = Yup.object({
  sample_name: Yup.string().min(2).required("Please Enter Sample Name"),
  fk_location_id: Yup.string().required("Please Select location"),
  // labagent: Yup.string().required("Please Enter Value"),
  fk_status_id: Yup.string().required("Please Select Status"),
  fk_patient_id: Yup.string().required("Please Select Patient"),
  fk_lab_id: Yup.string().required("Please Select Lab"),
  hesn_req_id: Yup.string().required("Please Enter HSN Req. Id"),
  result: Yup.string().required("Please Enter Result"),
  collection_time: Yup.string().required("Please Enter Collection Time"),
  sp_remark: Yup.string().min(3).required("Please Enter Remark"),
});

export const masterSchema = Yup.object({
  mmstr_name_en: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter English Master Name"),
  mmstr_name_ar: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter Arabic Master Name"),
});

export const VendorSchema = Yup.object({
  vendor_account_name: Yup.string()
    .matches("^[a-zA-Z ]*$", "Numbers are not allowed")
    .required("Acount Name is required"),
  vendor_legal_name: Yup.string()
    .matches("^[a-zA-Z ]*$", "Numbers are not allowed")
    .required("Legal Name is required"),
  vendor_mobile: Yup.string().required("Phone Number is required"),
  // vendor_address: Yup.string().required("Address is required"),
  vendor_city: Yup.string().required("City is required"),
  vendor_country: Yup.string().required("Country is required"),
  vendor_pin: Yup.string().matches().required("Pin is required"),
});

export const couponSchema = Yup.object({
  // cp_name: Yup.string()
  //   .min(2)
  //   .max(40)
  //   .required("Please Enter Coupon Name"),
  // cp_max_discount: Yup.string().required("Please Add Max Discount"),
  // cp_discountValue: Yup.string().required("Please add discount value"),
  cp_toDate: Yup.string().required("Please add coupon expiry date"),
  cp_fromDate: Yup.string().required("Please add coupon start date"),
  // fk_test_ids: Yup.array().min(1).required("Please select test"),
  // cp_limit_per_user: Yup.string().required("Please add limit per user"),
  // fk_location_ids: Yup.array().min(1).required("Please select location"),
  // fk_test_ids: Yup.array().min(1).required("Please select test"),
  // fk_package_ids: Yup.array().min(1).required("Please select package"),
  // fk_user_ids: Yup.array().min(1).required("Please select user"),
  // cp_numUses: Yup.string().required("Please add number of uses"),
  // cp_code_prefix: Yup.string().required("Please add Coupon code prefix"),
  // cp_code_count: Yup.string().required("Please add Coupon code count"),
});

export const makeMonthlyPaymentSchema = Yup.object({
  payment_id: Yup.string().required("Please Select Payment Type"),
});

export const editCouponSchema = Yup.object({
  cp_toDate: Yup.string().required("Please add coupon expiry date"),
  cp_fromDate: Yup.string().required("Please add coupon start date"),
  // fk_test_ids: Yup.array().min(1).required("Please select test"),
  // fk_user_ids: Yup.array().min(1).required("Please select user"),
  // fk_package_ids: Yup.array().min(1).required("Please select Package"),
  // fk_loc_ids: Yup.array().min(1).required("Please select Locations"),
});

export const reservationAddressSchema = Yup.object({
  ua_recipient: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter Recipient Name"),
  ua_building: Yup.string().min(2).required("Please Enter Building Name"),
  ua_street: Yup.string().min(2).required("Please Enter Street Name"),
  ua_neighborhood: Yup.string()
    .min(2)
    .required("Please Enter Neighbourhood Name"),
  ua_city: Yup.string().min(2).required("Please Enter City Name"),
  ua_state: Yup.string().min(2).required("Please Enter State Name"),
  ua_country: Yup.string().min(2).required("Please Enter Country Name"),
  ua_postalCode: Yup.number().min(4).required("Please Enter Postal Code"),
});

export const incentiveSchema = Yup.object({
  inct_value: Yup.number().required("Please add incentive value"),
  inct_to_date: Yup.string().required("Please add incentive expiry date"),
  inct_from_date: Yup.string().required("Please add incentive start date"),
});

export const regionSchema = Yup.object({
  rgn_name_en: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter English Region Name"),
  rgn_name_ar: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter Arabic Region Name"),
});

export const regionLocSchema = Yup.object({
  fk_region_id: Yup.string().required("Please Select the Region Name"),
  fk_location_id: Yup.string().required("Please Select the Location Name"),
});

export const qualitycontroleSchema = Yup.object({
  fk_q_qc_id: Yup.string().required("Please Select QC"),
  fk_lot_id: Yup.string().required("Please Select Lot"),
  fk_test_id: Yup.string().required("Please Select Test"),
  // fk_test_param_id: Yup.string().required("Please Select Test Params"),
  // qres_value: Yup.number().min(1).max(150).required("Please Enter Result"),
  // qres_time_of_result: Yup.date().required("Please Enter the Date Time"),
});

export const OrganismSchema = Yup.object({
  org_name_en: Yup.string().min(2).max(40).required("Please Enter En Name"),
  org_name_ar: Yup.string().min(2).max(40).required("Please Enter Ar Name"),
});

export const AntibioticSchema = Yup.object({
  atb_main_name_en: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter En Name"),
  atb_main_name_ar: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter Ar Name"),
});

export const AntibioticResultTypeSchema = Yup.object({
  mtrt_name_en: Yup.string().min(2).max(40).required("Please Enter En Name"),
  mtrt_name_ar: Yup.string().min(2).max(40).required("Please Enter Ar Name"),
});
