/* eslint-disable import/no-anonymous-default-export */
import { getParams } from '../helper/commonHelper';
import Api from './Api';
import { toast } from 'react-toast';
export default {

  // User Info Start
  async getUserProfile(payload) {
    const response = await Api().get(
      `api/dashboard/user/${payload}`
    );
    return response;
  },
  async updateUserInfo(payload) {
    const response = await Api().post(
      `api/dashboard/user/update-user-info`,
      payload
    );
    return response;
  },

  // User Info End

  // Payment Mode Section Start
  async savePaymentMode(payload) {
    const response = await Api().post(
      `api/dashboard/masters/pay-mode/save`,
      payload
    );
    return response;
  },
  async getPaymentModeList(payload) {
    const response = await Api().get(
      `api/dashboard/masters/pay-mode?search=${payload}`
    );
    return response;
  },
  async getSinglePaymentMode(payload) {
    const response = await Api().get(
      `api/dashboard/masters/pay-mode/${payload}`
    );
    return response;
  },
  async setPaymentModeStatus(payload) {
    const response = await Api().post(
      `api/dashboard/masters/pay-mode/status`,
      payload
    );
    return response;
  },
  // Payment Mode Section End

  // Location Section Start
  async saveLocation(payload) {
    const response = await Api().post(
      `api/dashboard/masters/hos-loc/save`,
      payload
    );
    return response;
  },
  async getLocationList(payload) {
    const response = await Api().get(
      `api/dashboard/masters/hos-loc/${payload ? payload : ''}`
    );
    return response;
  },
  async getAllLocationList(payload) {
    const response = await Api().get(`api/dashboard/masters/hos-loc/all/location/list/type?${payload}`);
    return response;
  },
  async getAllTestBaseLocationList(payload) {
    const response = await Api().get(
      `api/dashboard/masters/hos-loc/sample_loc/${payload}`
    );
    return response;
  },
  async getSingleLocation(payload) {
    const response = await Api().get(
      `api/dashboard/masters/hos-loc/single/${payload}`
    );
    return response;
  },
  async setLocationStatus(payload) {
    const response = await Api().post(
      `api/dashboard/masters/hos-loc/status`,
      payload
    );
    return response;
  },
  async getDailyLocationBasedRevenue(payload) {
    const response = await Api().get(`/api/dashboard/sales/dailyLocationBasedRevenue?loc_id=${payload.loc_id}&start_date=${payload.start_date}&end_date=${payload.end_date}`)
    return response;
  },
  async downloadDailyLocationBasedRevenuePDF(payload) {
    const response = await Api().post(`/api/dashboard/sales/dailyLocationBasedRevenuePDF`, payload)
    return response;
  },
  // Location Section End

  // Test Location Section Start
  async saveLocationTest(payload) {
    const response = await Api().post(
      `api/dashboard/masters/loc-test/`,
      payload
    );
    console.log('response=>', response);
    return response;
  },
  async getLocationTestList(payload) {
    const response = await Api().get(
      `api/dashboard/masters/loc-test/${payload}`
    );
    return response;
  },
  async setLocationTestStatus(payload) {
    const response = await Api().post(
      `api/dashboard/masters/loc-test/status`,
      payload
    );
    return response;
  },
  // Test Location Section End

  // Hospital Section Start
  async saveHospital(payload) {
    const response = await Api().post(
      `api/dashboard/masters/hospital/save`,
      payload
    );
    return response;
  },
  async getHospitalList() {
    const response = await Api().get(`api/dashboard/masters/hospital`);
    return response;
  },
  async getAllStatusHospitals(payload) {
    const response = await Api().get(`api/dashboard/masters/hospital/all/?${payload}`);
    return response;
  },
  async getAllHospitalBaseLocation(payload) {
    const response = await Api().get(`api/dashboard/masters/hos-loc/all/location/list/type/?${payload}`);
    return response;
  },
  async getSingleHospital(payload) {
    const response = await Api().get(
      `api/dashboard/masters/hospital/${payload}`
    );
    return response;
  },
  async setHospitalStatus(payload) {
    const response = await Api().post(
      `api/dashboard/masters/hospital/status`,
      payload
    );
    return response;
  },
  // Hospital Section End


  // Clinic Section Start
  async saveClinic(payload) {
    const response = await Api().post(
      `api/dashboard/masters/clinic/save`,
      payload
    );
    return response;
  },
  async getClinicList(payload) {
    let param = getParams(payload)
    const response = await Api().get(`api/dashboard/masters/clinic?${param}`);
    return response;
  },
  async getSingleClinic(mc_id) {
    const response = await Api().get(`api/dashboard/masters/clinic/${mc_id}`);
    return response;
  },
  async getReferralClinicList() {
    const response = await Api().get(`api/dashboard/masters/clinic/get-referral-clinic`);
    return response;
  },
  async getReferralClinicsBasedOnLocation(fk_hsp_loc_id) {
    const response = await Api().get(`api/dashboard/masters/clinic/get-referral-clinic-based-on-loc?loc_id=${fk_hsp_loc_id}`);
    return response;
  },
  async getClinicsBasedOnLocation(fk_hsp_loc_id) {
    const response = await Api().get(`api/dashboard/masters/clinic/get-clinics-based-on-loc?loc_id=${fk_hsp_loc_id}`);
    return response;
  },
  async getAllCreditLogsByClinicId(mc_id) {
    const response = await Api().get(`api/dashboard/masters/clinic/credit-details-list/${mc_id}`)
    return response
  },
  async addCreditAmountToClinic({ mc_id, add_credit_amount }) {
    const response = await Api().post(`api/dashboard/masters/clinic/add-credit/${mc_id}`, { add_credit_amount })
    return response
  },
  async getSpendCreditLogsByClinicId(mc_id) {
    const response = await Api().get(`api/dashboard/masters/clinic/credit-spend-details-list/${mc_id}`)
    return response
  },
  async getRefClinicTestList(mc_id) {
    const response = await Api().get(`api/dashboard/masters/clinic/rc_test_list/${mc_id}`)
    return response
  },
  async getAllOutsourcedClinicList() {
    const response = await Api().get(`api/dashboard/masters/clinic/get-all-outsourced-clinics`);
    return response;
  },
  // Clinic Section End


  // Notification Section Start
  async saveNotification(payload) {
    const response = await Api().post(
      `api/dashboard/notification/save`,
      payload
    );
    return response;
  },
  async getNotificationList(payload) {
    const params = new URLSearchParams(payload);
    // console.log(params.toString()); // output: val1=val&val2=val
    let queryString;
    if (params) {
      queryString = params.toString()
    }
    const response = await Api().get(`api/dashboard/notification?${queryString}`);
    return response;
  },
  async getUnreadNotificationCount() {
    const response = await Api().get(`api/dashboard/notification/unread-notification-count`);
    return response;
  },
  async updateNotificationStatus(payload) {
    const response = await Api().post(
      `api/dashboard/notification/status`,
      payload
    );
    return response;
  },
  // Notification Section End


  // Questionnaire Section Start
  async saveQuestionnaire(payload) {
    const response = await Api().post(
      `api/dashboard/tests/questionnaire/save`,
      payload
    );
    return response;
  },
  async getQuestionnaireList() {
    const response = await Api().get(`api/dashboard/tests/questionnaire`);
    return response;
  },
  async getSingleQuestionnaire(qstn_id) {
    const response = await Api().get(`api/dashboard/tests/questionnaire/${qstn_id}`);
    return response;
  },
  // Questionnaire Section End


  // Test Color Section Start
  async saveTestColor(payload) {
    const response = await Api().post(
      `api/dashboard/masters/test-color/save`,
      payload
    );
    return response;
  },
  async getTestColorList(payload) {
    let param = getParams(payload)
    const response = await Api().get(`api/dashboard/masters/test-color?${param}`);
    return response;
  },
  async getTestColor(mclr_id) {
    const response = await Api().get(`api/dashboard/masters/test-color/${mclr_id}`);
    return response;
  },
  // Test Color Section End

  // Packages Section Start
  async savePackage(payload) {
    const response = await Api().post(
      `api/dashboard/masters/package/save`,
      payload
    );
    return response;
  },
  async getPackageList(payload) {
    const response = await Api().get(
      `api/dashboard/masters/package?search=${payload}`
    );
    return response;
  },

  async importPackages(payload) {
    const response = await Api().post(`api/dashboard/masters/package/import`, payload);
    return response;
  },

  async getReservatoinPackageList(payload) {
    const response = await Api().get(
      `api/dashboard/masters/package/reservation-package?search=${payload}`
    );
    return response;
  },
  async getAllRevPackagesForReferralClinic(payload) {
    const response = await Api().get(
      `api/dashboard/masters/package/reservation-packages/referral-clinic/${payload}`
    );
    return response;
  },
  async getSinglePackage(payload) {
    const response = await Api().get(
      `api/dashboard/masters/package/${payload}`
    );
    return response;
  },
  async setPackageStatus(payload) {
    const response = await Api().post(
      `api/dashboard/masters/package/status`,
      payload
    );
    return response;
  },
  async deletePackage(payload) {
    const response = await Api().post(
      `api/dashboard/masters/package/delete`,
      payload
    );
    return response;
  },
  // Packages Section End

  // Test Section Start
  async saveTest(payload) {
    const response = await Api().post(
      `api/dashboard/masters/test/save`,
      payload
    );
    return response;
  },
  async getTestList(payload) {
    let param = 'All';
    if (typeof payload === 'object' && !Array.isArray(payload) && payload !== null) {
      const urlParams = new URLSearchParams(payload);
      console.log("Payload is an object", urlParams);
      param = urlParams.toString();
    } else if (typeof payload === 'string') {
      param = `search=${payload}`;
    } else {
      console.log("Payload is neither a plain object nor a string");
      param = `search=All`; // or throw an error
    }
    const response = await Api().get(
      `api/dashboard/masters/test?${param}`
    );
    return response;
  },
  async importTest(payload) {
    const response = await Api().post(`api/dashboard/masters/test/import`, payload);
    return response;
  },

  //get only for researvation all test OR for clinic user_role = 13
  async getTestListForReservation(payload) {
    const response = await Api().get(
      `api/dashboard/masters/test/reservation?search=${payload}`
    );
    return response;
  },
  async getTestListForReservationReferralClinic(payload) {
    const response = await Api().get(`api/dashboard/masters/test/reservation-referral-clinic/${payload}`);
    return response;
  },

  async getSingleTest(payload) {
    const response = await Api().get(`api/dashboard/masters/test/${payload}`);
    return response;
  },
  async getTestCounter(payload) {
    const response = await Api().get(`api/dashboard/masters/test/last/counter/`);
    return response;
  },
  async setTestStatus(payload) {
    const response = await Api().post(
      `api/dashboard/masters/test/status`,
      payload
    );
    return response;
  },
  async deleteTest(payload) {
    const response = await Api().post(
      `api/dashboard/masters/test/delete`,
      payload
    );
    return response;
  },

  async getCRMTestList(clinic_id, hsp_loc_id) {
    const response = await Api().get(`api/dashboard/masters/test/crm/testlist?clinic=${clinic_id}&location=${hsp_loc_id}`)
    return response
  },
  // Test Section End

  // Test-Parameter Section Start
  async saveSubTest(payload) {
    const response = await Api().post(
      `api/dashboard/masters/subtest/save`,
      payload
    );
    return response;
  },
  async getSubTestList(payload) {
    const response = await Api().get(
      `api/dashboard/masters/subtest/${payload}`
    );
    return response;
  },
  async getSingleSubTest(payload) {
    const response = await Api().get(
      `api/dashboard/masters/subtest/single/${payload}`
    );
    return response;
  },
  async setSubTestStatus(payload) {
    const response = await Api().post(
      `api/dashboard/masters/subtest/status`,
      payload
    );
    return response;
  },
  async deleteSubTestStatus(payload) {
    const response = await Api().post(
      `api/dashboard/masters/subtest/delete`,
      payload
    );
    return response;
  },
  async importTestParams(payload) {
    const response = await Api().post(`api/dashboard/masters/subtest/import`, payload);
    return response;
  },
  // Test-Parameter Section End

  // Category Section Start
  async saveCategory(payload) {
    const response = await Api().post(
      `api/dashboard/masters/category/save`,
      payload
    );
    return response;
  },
  async getCategoryList(payload) {
    const param = getParams(payload)
    const response = await Api().get(
      `api/dashboard/masters/category?${param}`
    );
    return response;
  },
  async setCategoryStatus(payload) {
    const response = await Api().post(
      `api/dashboard/masters/category/category-status`,
      payload
    );
    return response;
  },
  async deleteCategory(payload) {
    const response = await Api().post(
      `api/dashboard/masters/category/delete`,
      payload
    );
    return response;
  },
  // Category Section End

  // PriceList Section Start
  async savePriceList(payload) {
    const response = await Api().post(
      `api/dashboard/masters/price-list/save`,
      payload
    );
    return response;
  },
  async getSinglePriceList(payload) {
    const response = await Api().get(
      `api/dashboard/masters/price-list/${payload}`
    );
    return response;
  },
  async getPriceList() {
    const response = await Api().get(`api/dashboard/masters/price-list`);
    return response;
  },
  async setPriceListStatus(payload) {
    const response = await Api().post(
      `api/dashboard/masters/price-list/status`,
      payload
    );
    return response;
  },
  async setMUserStatus(payload) {
    const response = await Api().post(
      `api/dashboard/user/user-status`,
      payload
    );
    return response;
  },

  async saveMUser(payload) {
    try {
      let {
        user_id,
        user_email: email,
        user_first_name: firstname,
        user_last_name: lastname,
        user_mobile: mobile,
        user_password: password,
        user_role: user_role_id,
        hos_id: fk_hos_id,
        loc_id: fk_hsp_loc_id,
      } = payload;
      let body = {
        user_id,
        email,
        firstname,
        lastname,
        mobile: mobile + '',
        password,
        user_role_id: +user_role_id,
        fk_hos_id,
        fk_hsp_loc_id,
      };

      const res = await Api().post('api/dashboard/user/', payload);
      // console.log("Register Response", res.data, body)
      if (res && res.data.status) {
        toast.success(res.data.message);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log('Error in Register Api ', error);
      return null;
    }
  },

  async getAllMUsersList() {
    const response = await Api().get(
      `api/dashboard/user`
    );
    return response;
  },
  async savePriceListDetails(payload) {
    const response = await Api().post(
      `api/dashboard/masters/price-list/save-details`,
      payload
    );
    return response;
  },
  async getSinglePriceListDetails(payload) {
    let price_id = payload.price_id
    delete payload.price_id
    let param = getParams(payload)
    const response = await Api().get(
      `api/dashboard/masters/price-list/details/${price_id}?${param}`
    );
    return response;
  },
  async setPriceListDetailStatus(payload) {
    const response = await Api().post(
      `api/dashboard/masters/price-list/remove-detail`,
      payload
    );
    return response;
  },
  async importPriceListItems(payload) {
    const response = await Api().post(
      `api/dashboard/masters/price-list/import`,
      payload
    );
    return response;
  },
  async duplicatePriceList(payload) {
    const response = await Api().post(
      `api/dashboard/masters/price-list/duplicate-pl`,
      payload
    );
    return response;
  },
  // PriceList Section End

  // Gender Section Start
  async getGenderList() {
    const response = await Api().get(`api/dashboard/masters/gender`);
    return response;
  },

  async deleteGender(gender_id) {
    const response = await Api().delete(`api/dashboard/masters/gender/delete/${gender_id}`);
    return response;
  },


  // Gender Section End

  // Country Section Start
  async getCountryList() {
    const response = await Api().get(`api/dashboard/masters/country`);
    return response;
  },
  // Country Section End

  // City Section Start
  async getCityList() {
    const response = await Api().get(`api/dashboard/masters/city`);
    return response;
  },
  async getCity(payload) {
    const response = await Api().get(
      `api/dashboard/masters/city/all?${payload}`
    );
    return response;
  },
  async getCityListByState(payload) {
    const response = await Api().get(`api/dashboard/masters/city/${payload}`);
    return response;
  },
  // City Section End

  // State Section Start
  async getStateList() {
    const response = await Api().get(`api/dashboard/masters/state`);
    return response;
  },
  async getStateListByCountry(payload) {
    const response = await Api().get(`api/dashboard/masters/state/${payload}`);
    return response;
  },
  // State Section End

  // Sample Type Section Start
  async getSampleTypeList(payload) {
    let param = getParams(payload)
    const response = await Api().get(`api/dashboard/masters/sample-type/list?${param}`);
    return response;
  },
  async saveSampleType(payload) {
    const response = await Api().post(
      `api/dashboard/masters/sample-type/save`,
      payload
    );
    return response;
  },
  async getSingleSampleType(payload) {
    const response = await Api().get(
      `api/dashboard/masters/sample-type/${payload}`
    );
    return response;
  },
  async setSampleTypeStatus(payload) {
    const response = await Api().post(
      `api/dashboard/masters/sample-type/status`,
      payload
    );
    return response;
  },
  //  Sample Type Section End

  // Control list API Starts
  async getControlList() {
    const response = await Api().get(`api/dashboard/quality-control/qc/list`);
    return response;
  },

  async addControlList(payload) {
    const response = await Api().post(
      `api/dashboard/quality-control/qc/save`,
      payload
    );
    return response;
  },
  async setControlStatus(payload) {
    const response = await Api().post(`api/dashboard/quality-control/qc/status`, payload);
    return response;
  },
  // Control list API Ends

  // Qc Test API Starts

  async getQcTestList() {
    const response = await Api().get(`api/dashboard/masters/test`);
    return response;
  },

  async getQcById(qcId) {
    const response = await Api().get(
      `api/dashboard/quality-control/qc/${qcId}`
    );
    return response;
  },
  async getQcLotById(payload) {
    const response = await Api().get(
      `api/dashboard/quality-control/qc/${payload.qc_id}/${payload.lot_id}`
    );
    return response;
  },

  async updateQcTest(payload) {
    const response = await Api().post(
      `api/dashboard/quality-control/qc/save`,
      payload
    );
    return response;
  },

  async deleteQcTest(payload) {
    const response = await Api().post(
      `api/dashboard/quality-control/qc/delete`,
      payload
    );
    return response;
  },

  // Qc Test API Ends

  // Qc Test Analyzer API Starts
  async getAllTestAnalyzer() {
    const response = await Api().get(`api/dashboard/tests/lab-analyzer`);
    return response;
  },

  async getTestAnalyzerList(qcId) {
    const response = await Api().get(
      `api/dashboard/quality-control/qc-analyzer/${qcId}`
    );
    return response;
  },

  async addAnalyzer(payload) {
    const response = await Api().post(
      `api/dashboard/quality-control/qc-analyzer/save`,
      payload
    );
    return response;
  },

  async deleteAnalyzer(payload) {
    const response = await Api().post(
      `api/dashboard/quality-control/qc-analyzer/delete`,
      payload
    );
    return response;
  },

  // Qc Test Analyzer API Ends

  // Qc Test Lots API Starts

  async getAllTestLots() {
    const response = await Api().get(`api/dashboard/quality-control/lot/list`);
    return response;
  },

  getAllLotsByQcId(qcId) {
    return Api().get(`/api/dashboard/quality-control/lot/qc/${qcId}`);
  },
  async updateLotTeslist(payload) {
    const response = await Api().post(
      `api/dashboard/quality-control/lot/value`,
      payload
    );
    return response;
  },

  async addLot(payload) {
    const response = await Api().post(
      `api/dashboard/quality-control/lot/save`,
      payload
    );
    return response;
  },

  async getLotById(lotId) {
    const response = await Api().get(
      `api/dashboard/quality-control/lot/${lotId}`
    );
    return response;
  },

  async toggleLotActiveStatus(payload) {
    const response = await Api().post(
      `api/dashboard/quality-control/lot/status`,
      payload
    );
    return response;
  },

  // Qc Test Lots API Ends
  async getPermissionsByRoleID(payload) {
    const response = await Api().get(`api/dashboard/masters/role/${payload}`);
    return response;
  },

  async getAllPermissions() {
    const response = await Api().get(`api/dashboard/masters/permission/list`);
    return response;
  },

  async saveRole(payload) {
    const response = await Api().post(
      `api/dashboard/masters/role/save`,
      payload
    );
    return response;
  },

  //Fav Start
  async setFavourite(payload) {
    const response = await Api().post(
      `api/dashboard/masters/favourite/`,
      payload
    );
    return response;
  },
  async updateFavourite(payload) {
    console.log("payload", payload)
    const response = await Api().post(
      `api/dashboard/masters/favourite/update`,
      payload
    );
    return response;

  },
  async getUserBasedAllFav() {
    const response = await Api().get(`api/dashboard/masters/favourite/`);
    return response;
  },

  async addCommonAttachment(payload) {
    let progress = 0;
    let response = await Api().post(
      `api/dashboard/masters/hospital/attach_file`,
      payload,
      {
        onUploadProgress: (data) => {
          progress = Math.round((100 * data.loaded) / data.total);
        },
      }
    );
    response.data.progress = progress;
    return response;
  },
  //Fav End

  // Warehouse Vendor Start
  async getVendorList() {
    const response = await Api().get(`api/dashboard/warehouse/vendor`);
    return response;
  },
  async getOneVendor(v_id) {
    const response = await Api().get(`api/dashboard/warehouse/vendor/${v_id}`);
    return response;
  },
  async addVendor(payload) {
    const response = await Api().post(
      `api/dashboard/warehouse/vendor`,
      payload
    );
    return response;
  },

  async updateVendor(v_id, payload) {
    const response = await Api().post(
      `api/dashboard/warehouse/vendor/${v_id}`,
      payload
    );
    return response;
  },
  async updateStatusVendor(id, payload) {
    const response = await Api().post(
      `api/dashboard/warehouse/vendor/status/${id}`,
      payload
    );
    return response;
  },
  async deleteVendor(payload) {
    const response = await Api().post(
      `api/dashboard/warehouse/vendor/delete`,
      payload
    );
    return response;
  },

  // Warehouse Vendor End

  // Warehouse Vendor Item Starts
  async getVendorItemList() {
    const response = await Api().get(`api/dashboard/warehouse/vendor-item`);
    return response;
  },
  async getVendorItemListById(id) {
    const response = await Api().get(
      `api/dashboard/warehouse/vendor-item/vendor/${id}`
    );
    return response;
  },
  async getOneVendorItem(v_id) {
    const response = await Api().get(
      `api/dashboard/warehouse/vendor-item/${v_id}`
    );
    return response;
  },
  async addVendorItem(payload) {
    const response = await Api().post(
      `api/dashboard/warehouse/vendor-item`,
      payload
    );
    return response;
  },
  async updateVendorItem(v_id, payload) {
    const response = await Api().post(
      `api/dashboard/warehouse/vendor-item/${v_id}`,
      payload
    );
    return response;
  },
  async updateStatusVendorItem(id, payload) {
    const response = await Api().post(
      `api/dashboard/warehouse/vendor-item/status/${id}`,
      payload
    );
    return response;
  },
  // Warehouse Vendor Item Ends

  // Warehouse Vendor Contact Starts
  async getVendorContactListByVid(id) {
    const response = await Api().get(
      `api/dashboard/warehouse/vendor-contact/vendor/${id}`
    );
    return response;
  },
  async getVendorContactList() {
    const response = await Api().get(`api/dashboard/warehouse/vendor-contact`);
    return response;
  },
  async getOneVendorContact(v_id) {
    const response = await Api().get(
      `api/dashboard/warehouse/vendor-contact/${v_id}`
    );
    return response;
  },
  async updateVendorContactStatus(v_id, payload) {
    const response = await Api().post(
      `api/dashboard/warehouse/vendor-contact/status/${v_id}`,
      payload
    );
    return response;
  },
  async addVendorContact(payload) {
    const response = await Api().post(
      `api/dashboard/warehouse/vendor-contact`,
      payload
    );
    return response;
  },
  async updateVendorContact(id, payload) {
    const response = await Api().post(
      `api/dashboard/warehouse/vendor-contact/${id}`,
      payload
    );
    return response;
  },
  // Warehouse Vendor Contact Ends

  // Warehouse Items Starts
  async getItemsList() {
    const response = await Api().get(`api/dashboard/warehouse/item`);
    return response;
  },
  async getTItemsList() {
    const response = await Api().get(`api/dashboard/warehouse/item/titems`);
    return response;
  },
  async getOneItems(v_id) {
    const response = await Api().get(`api/dashboard/warehouse/item/${v_id}`);
    return response;
  },
  async updateItemsStatus(v_id, payload) {
    const response = await Api().post(
      `api/dashboard/warehouse/item/status/${v_id}`,
      payload
    );
    return response;
  },
  async deleteItem(payload) {
    const response = await Api().post(
      `api/dashboard/warehouse/item/delete/${payload.item_id}`,
      payload
    );
    return response;
  },
  async addItems(payload) {
    const response = await Api().post(`api/dashboard/warehouse/item`, payload);
    return response;
  },
  async updateItems(id, payload) {
    const response = await Api().post(
      `api/dashboard/warehouse/item/${id}`,
      payload
    );
    return response;
  },
  //Storage Location Item (Stock)
  async getStok() {
    const response = await Api().get(
      `api/dashboard/masters/storagelocationitem`
    );
    return response;
  },
  // Warehouse Items Ends



  // Master Units Starts
  async getMUnitsList() {
    const response = await Api().get(`api/dashboard/masters/unit`);
    return response;
  },
  // async getOneUnits(v_id) {
  //   const response = await Api().get(`api/dashboard/warehouse/unit/${v_id}`);
  //   return response;
  // },
  // async updateUnitsStatus(v_id, payload) {
  //   const response = await Api().post(
  //     `api/dashboard/warehouse/unit/status/${v_id}`,
  //     payload
  //   );
  //   return response;
  // },
  async addMUnit(payload) {
    const response = await Api().post(`api/dashboard/masters/unit`, payload);
    return response;
  },
  async changeStatusMUnit(payload) {
    const response = await Api().post(`api/dashboard/masters/unit/status`, payload);
    return response;
  },
  // async updateUnits(id, payload) {
  //   const response = await Api().post(
  //     `api/dashboard/warehouse/unit/${id}`,
  //     payload
  //   );
  //   return response;
  // },
  async deleteMUnit(payload) {
    const response = await Api().post(
      `api/dashboard/masters/unit/delete`,
      payload
    );
    return response;
  },
  // Master Units Ends

  // Warehouse Units Starts
  async getUnitsList() {
    const response = await Api().get(`api/dashboard/warehouse/unit`);
    return response;
  },
  async getOneUnits(v_id) {
    const response = await Api().get(`api/dashboard/warehouse/unit/${v_id}`);
    return response;
  },
  async updateUnitsStatus(v_id, payload) {
    const response = await Api().post(
      `api/dashboard/warehouse/unit/status/${v_id}`,
      payload
    );
    return response;
  },
  async addUnits(payload) {
    const response = await Api().post(`api/dashboard/warehouse/unit/${payload.unit_id}`, payload);
    return response;
  },
  async updateUnits(id, payload) {
    const response = await Api().post(
      `api/dashboard/warehouse/unit/${id}`,
      payload
    );
    return response;
  },
  async deleteUnit(payload) {
    const response = await Api().post(
      `api/dashboard/warehouse/unit/delete/${payload.unit_id}`,
      payload
    );
    return response;
  },
  // Warehouse Units Ends

  // ------------------------------------------ Coupon Start ---------------------------------------------------
  async getAllCoupons(payload) {
    const param = getParams(payload)
    const response = await Api().get(`/api/dashboard/masters/coupon?${param}`);
    return response;
  },

  async saveCoupon(payload, id) {
    const response = await Api().post(`/api/dashboard/masters/coupon/${id}`, payload);
    return response;
  },

  async updateCoupon(payload, id) {
    const response = await Api().post(`/api/dashboard/masters/coupon/update/${id}`, payload);
    return response;
  },

  async statusChangeCoupon(id, payload) {
    const response = await Api().post(
      `api/dashboard/masters/coupon/status/${id}`,
      payload
    );
    return response;
  },

  async deleteCoupon(id, payload) {
    const response = await Api().post(
      `api/dashboard/masters/coupon/delete/${id}`,
      payload
    );
    return response;
  },

  async getCouponCodesByCouponID(cp_id) {
    const response = await Api().get(`/api/dashboard/masters/coupon/codes/${cp_id}`);
    return response;
  },

  async validateCoupon(payload) {
    const response = await Api().post(`/api/dashboard/masters/coupon/validate`, payload);
    return response;
  },

  async getCouponLogsWithFilters(payload) {
    const param = getParams(payload)
    const response = await Api().post(`/api/dashboard/masters/coupon/logs?${param}`, payload);
    return response;
  },



  // ------------------------------------------ Coupon End ----------------------------------------------------

  //All Logs
  async getAllLogs(payload) {
    const param = getParams(payload)
    const response = await Api().get(`api/dashboard/masters/all_logs?${param}`);
    return response;
  },
  async getAllLogsFilter(payload) {
    const response = await Api().post(`api/dashboard/masters/all_logs/filter`, payload);
    return response;
  },
  //All Logs End

  //Urgent Logs
  async getUrgentLogs(payload) {
    const param = getParams(payload)
    const response = await Api().get(`api/dashboard/masters/urgent_logs?${param}`);
    return response;
  },
  async getUrgentLogsFilter(payload) {
    const response = await Api().post(`api/dashboard/masters/urgent_logs/filter`, payload);
    return response;
  },
  //Urgent Logs End



  //Report Generated Logs
  async getReportGeneratedLogs(payload) {
    const param = getParams(payload)
    const response = await Api().get(`api/dashboard/masters/report_generated_logs?${param}`);
    return response;
  },
  async getReportGeneratedLogsFilter(payload) {
    const response = await Api().post(`api/dashboard/masters/report_generated_logs/filter`, payload);
    return response;
  },
  //Report Generated Logs End


  //Purchase Order

  async getPurchaseOrder(payload) {
    const response = await Api().get(`api/dashboard/prequest?type=${payload}`);
    return response;
  },

  async getSinglePurchaseOrder(payload) {
    const response = await Api().get(`api/dashboard/prequest/${payload}`);
    return response;
  },


  async addPurchaseOrder(payload) {
    const response = await Api().post(`api/dashboard/prequest/`, payload);
    return response;
  },
  async setPurchaseOrderStatus(payload) {
    const response = await Api().post(
      `api/dashboard/prequest/po-status`,
      payload
    );
    return response;
  },
  async generatePOPdf(payload) {
    const response = await Api().post(
      `api/dashboard/prequest/generate-po-pdf`,
      payload
    );
    return response;
  },
  async setPurchaseOrderApproveStatus(payload) {
    const response = await Api().post(
      `api/dashboard/prequest/po-approve-status`,
      payload
    );
    return response;
  },
  async deletePurchaseOrder(payload) {
    const response = await Api().post(
      `api/dashboard/prequest/po-delete`,
      payload
    );
    return response;
  },
  // Coupon End

  // Warehosue Item Category
  async saveWarehouseItemCategory(payload) {
    const response = await Api().post(
      `api/dashboard/masters/warehouse-item-category/save`,
      payload
    );
    return response;
  },
  async deleteWarehouseItemCategory(payload) {
    const response = await Api().post(
      `api/dashboard/masters/warehouse-item-category/delete`,
      payload
    );
    return response;
  },
  async getWarehouseItemCategory() {
    const response = await Api().get(`api/dashboard/masters/warehouse-item-category`);
    return response;
  },
  async getSingleWarehouseItemCategory(wic_id) {
    const response = await Api().get(`api/dashboard/masters/warehouse-item-category/${wic_id}`);
    return response;
  },
  // End Warehouse Category

  // Methodology
  async saveMethodology(payload) {
    const response = await Api().post(
      `api/dashboard/masters/methodology/save`,
      payload
    );
    return response;
  },
  async deleteMethodology(payload) {
    const response = await Api().post(
      `api/dashboard/masters/methodology/delete`,
      payload
    );
    return response;
  },
  async getMethodology(payload) {
    let param = getParams(payload)
    const response = await Api().get(`api/dashboard/masters/methodology?${param}`);
    return response;
  },
  async getSingleMethodology(wic_id) {
    const response = await Api().get(`api/dashboard/masters/methodology/${wic_id}`);
    return response;
  },
  // End Methodology


  //Clinic Refferal
  async saveClinicRefferal(payload) {
    const response = await Api().post(
      `api/dashboard/masters/clinic-refferal/save`,
      payload
    );
    return response;
  },
  async deleteClinicRefferal(payload) {
    const response = await Api().post(
      `api/dashboard/masters/clinic-refferal/delete`,
      payload
    );
    return response;
  },
  async getClinicRefferal() {
    const response = await Api().get(`api/dashboard/masters/clinic-refferal`);
    return response;
  },
  async getSingleClinicRefferal(resv_ref_id) {
    const response = await Api().get(`api/dashboard/masters/clinic-refferal/${resv_ref_id}`);
    return response;
  },
  // End Clinic Refferal

  //Reference Ranges
  async getAllRefType() {
    const response = await Api().get(`api/dashboard/masters/reference_range/type/`);
    return response;
  },

  async getAllRefTypeInputs(type_id) {
    const response = await Api().get(
      `api/dashboard/masters/reference_range/type_input/get/inputs/${type_id}`
    );
    return response;
  },

  async saveReferenceRange(payload) {
    const response = await Api().post(
      `api/dashboard/masters/reference_range/reference/`,
      payload
    );
    return response;
  },

  async importReferenceRange(payload) {
    const response = await Api().post(`api/dashboard/masters/reference_range/reference_import`, payload);
    return response;
  },

  async getAllReferenceRange(payload) {
    let param = getParams(payload)
    const response = await Api().get(
      `api/dashboard/masters/reference_range/reference/?${param}`
    );
    return response;
  },
  async removeReferenceRange(payload) {
    const response = await Api().post(
      `api/dashboard/masters/reference_range/reference_remove/`,
      payload
    );
    return response;
  },

  async getSingleReferenceRange(mrr_id) {
    const response = await Api().get(
      `api/dashboard/masters/reference_range/reference/${mrr_id}`
    );
    return response;
  },

  async saveSubReferenceRange(payload) {
    const response = await Api().post(
      `api/dashboard/masters/reference_range/sub_reference/`,
      payload
    );
    return response;
  },

  async importSubReferenceRange(payload) {
    const response = await Api().post(`api/dashboard/masters/reference_range/sub_reference_import/`, payload);
    return response;
  },

  async getAllSubReferenceRange(fk_mrr_id) {
    const response = await Api().get(
      `api/dashboard/masters/reference_range/sub_reference/${fk_mrr_id}`
    );
    return response;
  },

  async removeRefRule(payload) {
    const response = await Api().post(
      `api/dashboard/masters/reference_range/sub_reference_remove/`,
      payload
    );
    return response;
  },

  // Global Setting Section Start
  async saveGlobalSetting(payload) {
    const response = await Api().post(
      `api/dashboard/masters/global_setting`,
      payload
    );
    return response;
  },
  //Purchase Order Ends

  // Get All labs (Hospital Location) Starts
  async getLabs() {
    const response = await Api().get(
      `api/dashboard/masters/hos-loc/all/location/list`
    );
    return response;
  },
  // Get All labs (Hospital Location) Ends

  // Storage Location Starts
  async addStorageLocation(payload) {
    const response = await Api().post(
      `api/dashboard/masters/storagelocation/create`,
      payload
    );
    return response;
  },

  async getAllStorageLocation() {
    const response = await Api().get(`api/dashboard/masters/storagelocation`);
    return response;
  },

  async getOneStorageLocation(id) {
    const response = await Api().get(
      `api/dashboard/masters/storagelocation/${id}`
    );
    return response;
  },

  async updateStorageLocation(id, payload) {
    const response = await Api().put(
      `api/dashboard/masters/storagelocation/${id}`,
      payload
    );
    return response;
  },

  async deleteStorageLocation(id) {
    const response = await Api().delete(
      `api/dashboard/masters/storagelocation/${id}`
    );
    return response;
  },
  // Storage Location Ends

  // Storage Location Item Starts
  async addStorageLocationItem(payload) {
    const response = await Api().post(
      `api/dashboard/masters/storagelocationitem/create`,
      payload
    );
    return response;
  },

  async getAllStorageLocationItem() {
    const response = await Api().get(
      `api/dashboard/masters/storagelocationitem/`
    );
    return response;
  },

  async getAllStorageLocationFilterdItem(payload) {
    const response = await Api().post(
      `api/dashboard/masters/storagelocationitem/filter`
      , payload);
    return response;
  },

  async getOneStorageLocationItem(id) {
    const response = await Api().get(
      `api/dashboard/masters/storagelocationitem/${id}`
    );
    return response;
  },

  async updateStorageLocationItem(id, payload) {
    const response = await Api().put(
      `api/dashboard/masters/storagelocationitem/${id}`,
      payload
    );
    return response;
  },

  async deleteStorageLocationItem(id) {
    const response = await Api().delete(
      `api/dashboard/masters/storagelocationitem/${id}`
    );
    return response;
  },
  // Storage Location Item Ends

  // Movement Api Starts
  async createMovementList(payload) {
    const response = await Api().post(
      `api/dashboard/masters/movements/create`,
      payload
    );
    return response;
  },
  async getAllGlobalSettings(payload) {
    let param = getParams(payload)
    const response = await Api().get(
      `api/dashboard/masters/global_setting?${param}`
    );
    return response;
  },
  async getSingleGlobalSetting(payload) {
    const response = await Api().get(
      `api/dashboard/masters/global_setting/${payload}`
    );
    return response;
  },
  async setGlobalSettingStatus(payload) {
    const response = await Api().post(
      `api/dashboard/masters/global_setting/status`,
      payload
    );
    return response;
  },
  async getMovementList() {
    const response = await Api().get(`api/dashboard/masters/movements`);
    return response;
  },
  async getOneMovement(id) {
    const response = await Api().get(`api/dashboard/masters/movements/${id}`);
    return response;
  },
  async updateMovement(id, payload) {
    const response = await Api().put(
      `api/dashboard/masters/movements/${id}`,
      payload
    );
    return response;
  },
  async deleteMovement(id) {
    const response = await Api().delete(
      `api/dashboard/masters/movements/${id}`
    );
    return response;
  },
  // Movement Api Ends

  // Grn Goods Received Api Starts
  async createGrnGoodsReceived(payload) {
    const response = await Api().post(
      `api/dashboard/masters/grn/create`,
      payload
    );
    return response;
  },
  async getGrnGoodsReceived() {
    const response = await Api().get(`api/dashboard/masters/grn`);
    return response;
  },
  async getGrnGoodsReceivedByPurchaseOrder(payload) {
    const response = await Api().post(`api/dashboard/masters/grn/grn_by_purchase_order`, payload);
    return response;
  },
  async getOneGrnGoodsReceived(id) {
    const response = await Api().get(`api/dashboard/masters/grn/${id}`);
    return response;
  },
  async updateGrnGoodsReceived(id, payload) {
    const response = await Api().put(
      `api/dashboard/masters/grn/${id}`,
      payload
    );
    return response;
  },
  async deleteGrnGoodsReceived(id) {
    const response = await Api().delete(`api/dashboard/masters/grn/${id}`);
    return response;
  },

  // Grn Goods Received Api Ends

  // Grn Goods Return Api Starts
  async createGrnGoodsReturn(payload) {
    const response = await Api().post(
      `dashboard/masters/grn-return/create`,

      payload
    );
    return response;
  },
  // Global Setting Section End

  async getGrnGoodsReturn() {
    const response = await Api().get(`api/dashboard/masters/grn-return`);
    return response;
  },
  async getOneGrnGoodsReturn(id) {
    const response = await Api().get(`api/dashboard/masters/grn-return/${id}`);
    return response;
  },
  async updateGrnGoodsReturn(id, payload) {
    const response = await Api().put(
      `api/dashboard/masters/grn-return/${id}`,
      payload
    );
    return response;
  },
  async deleteGrnGoodsReturn(id) {
    const response = await Api().delete(
      `api/dashboard/masters/grn-return/${id}`
    );
    return response;
  },

  // Grn Goods Return Api Ends

  // -------------------------------------- Incentives Start ----------------------------------------
  async getAllIncentivesByRoleId(role_id) {
    const res = await Api().get(`api/dashboard/masters/incentive/${role_id}`)
    return res;
  },

  async getRoleDetails(role_id) {
    const res = await Api().get(`api/dashboard/masters/role/${role_id}`)
    return res;
  },

  async addIncentive(payload) {
    const res = await Api().post(`api/dashboard/masters/incentive`, payload)
    return res;
  },

  async getIncentiveDetails(role_id, inct_id) {
    const res = await Api().get(`api/dashboard/masters/incentive/${role_id}/${inct_id}`)
    return res;
  },

  async updateIncentive(payload) {
    const res = await Api().post(`api/dashboard/masters/incentive/update`, payload)
    return res;
  },

  async getAvailablePackages(role_id) {
    const res = await Api().get(`api/dashboard/masters/incentive/packages/${role_id}`)
    return res;
  },

  async getAvailableTests(role_id) {
    const res = await Api().get(`api/dashboard/masters/incentive/tests/${role_id}`)
    return res;
  },

  async getAllIncentiveLogs() {
    const res = await Api().get(`api/dashboard/masters/incentive/logs`)
    return res;
  },
  async getAllIncentiveLogsForMUser(m_user_id) {
    const res = await Api().get(`api/dashboard/masters/incentive/logs/${m_user_id}`)
    return res;
  },

  async getIncentiveReportWithFilters(payload) {
    const res = await Api().post("api/dashboard/masters/incentive/report", payload)
    return res;
  },
  // -------------------------------------- Incentives End ----------------------------------------

  //Role Section Start

  async saveRoleMaxDiscount(payload) {
    const res = await Api().post("api/dashboard/masters/role/save-role-max-discount", payload)
    return res;
  },

  //Role Section End

  // Otp Section start
  async getDiscountOTPUsers() {
    const response = await Api().get(
      `api/dashboard/masters/otp/get-discount-otp-users`,
    );
    return response;
  },
  async sendOtp(payload) {
    const response = await Api().post(
      `api/dashboard/masters/otp/save`,
      payload
    );
    return response;
  },
  async verifyOtp(payload) {
    const response = await Api().post(
      `api/dashboard/masters/otp/verify`,
      payload
    );
    return response;
  },

  //Otp Section End


  // Region Section Start
  async saveRegion(payload) {
    const response = await Api().post(
      `api/dashboard/masters/region`,
      payload
    );
    return response;
  },
  async getRegionList(payload) {
    const param = getParams(payload)
    const response = await Api().get(
      `api/dashboard/masters/region?${param}`
    );
    return response;
  },

  async getSingleRegion(payload) {
    const response = await Api().get(`api/dashboard/masters/region/${payload}`);
    return response;
  },
  async setRegionStatus(payload) {
    const response = await Api().post(
      `api/dashboard/masters/region/status`,
      payload
    );
    return response;
  },


  async saveRegionLocation(payload) {
    const response = await Api().post(
      `api/dashboard/masters/region-loc`,
      payload
    );
    return response;
  },
  async getAllRegionLocations(payload) {
    const response = await Api().get(
      `api/dashboard/masters/region-loc/${payload.rgn_id}?search=${payload.type}`
    );
    return response;
  },
  async getSingleRegionLocation(payload) {
    const response = await Api().get(`api/dashboard/masters/region-loc/${payload.rgn_id}/${payload.rgn_loc_id}`);
    return response;
  },
  async setRegionLocationStatus(payload) {
    const response = await Api().post(
      `api/dashboard/masters/region-loc/status`,
      payload
    );
    return response;
  },
  // Region Section End


  // QC Result Section Start
  async saveQualityControleResult(payload) {
    const response = await Api().post(`api/dashboard/quality-control/result`, payload);
    return response;
  },
  async getAllQualityControleResultList(payload) {
    const response = await Api().get(
      `api/dashboard/quality-control/result?${payload}`
    );
    return response;
  },
  async getSingleQualityControleResult(payload) {
    const response = await Api().get(`api/dashboard/quality-control/result/${payload}`);
    return response;
  },
  async setQualityControleResultStatus(payload) {
    const response = await Api().post(`api/dashboard/quality-control/result/status`, payload);
    return response;
  },
  async getAllQualityControlsList(payload) {
    const response = await Api().get(`api/dashboard/quality-control/qc?${payload}`);
    return response;
  },

  getAllLotListByQcId(payload) {
    return Api().get(`/api/dashboard/quality-control/lot/filter/all/lotlist/?${payload}`);
  },
  getAllTestListByQcIdLotId(payload) {
    return Api().get(`/api/dashboard/quality-control/lot/filter/all/qclot/testlist/?${payload}`);
  },
  async updateQCResultCorrectionVal(payload) {
    const response = await Api().post(
      `api/dashboard/quality-control/result/correction-value`,
      payload
    );
    return response;
  },
  // QC Result Section End

  async updateUserLoc(payload) {
    const response = await Api().post(
      `api/dashboard/masters/user-loc`,
      payload
    );
    return response;
  },


  async setMUserOTPReceivingStatus(payload) {
    const response = await Api().post(
      `api/dashboard/user/user-otp-receiving-status`,
      payload
    );
    return response;
  },
  async setMUserApprovePOStatus(payload) {
    const response = await Api().post(
      `api/dashboard/user/user-approve-po-status`,
      payload
    );
    return response;
  },

  async getAllLabAnalyzers() {
    const response = await Api().get(`api/dashboard/tests/lab-analyzer`);
    return response;
  },

  async getAllLabAnalyzersByTest(payload) {
    const response = await Api().get(`api/dashboard/tests/lab-analyzer/by-test/${payload}`);
    return response;
  },

  async linkRRandLab(payload) {
    const response = await Api().post(`api/dashboard/masters/subtest/link-tp-rr-lab`, payload);
    return response;
  },

  async getCouponReportPDF(payload) {
    const response = await Api().post(`api/dashboard/download-pdf`, payload);
    return response;
  },

  async generateObjectToPDF(payload) {
    const response = await Api().post(`api/dashboard/download-pdf/generate-object-to-pdf`, payload);
    return response;
  },

  async generateObjectToHTML(payload) {
    const response = await Api().post(`api/dashboard/download-pdf/generate-object-to-html`, payload);
    return response;
  },

  async generateSmartReportPrevPDF(payload) {
    const response = await Api().post(`api/dashboard/download-pdf/generate-smart-report-prev-pdf`, payload);
    return response;
  },

  async getAllLinkedRRand(payload) {
    const response = await Api().get(`api/dashboard/masters/subtest/linked/list/${payload}`);
    return response;
  },

  async deleteRRandLab(payload) {
    const response = await Api().post(`api/dashboard/masters/subtest/delete-tp-rr-lab`, payload);
    return response;
  },

  async getTestBasedParams(payload) {
    const response = await Api().post(`api/dashboard/masters/subtest/params/`, payload);
    return response;
  },

  async getTestBasedParamsExcel(payload) {
    const response = await Api().post(`api/dashboard/masters/subtest/params/all/list/`, payload);
    return response;
  },

  // Antiobiotics APIs Start
  async getAllAntibiotics() {
    const response = await Api().get(`api/dashboard/masters/antibiotic/main/list`);
    return response;
  },

  async saveAntibiotic(payload) {
    const response = await Api().post(`api/dashboard/masters/antibiotic/main/save`, payload);
    return response;
  },

  async deleteAntibiotic(payload) {
    const response = await Api().post(`api/dashboard/masters/antibiotic/main/status`, payload);
    return response;
  },

  async importAntibiotic(payload) {
    const response = await Api().post(`api/dashboard/masters/antibiotic/main/import`, payload);
    return response;
  },
  // Antiobiotics APIs End


  // Organism APIs Start
  async getAllOrganisms() {
    const response = await Api().get(`api/dashboard/masters/organism/list`);
    return response;
  },

  async saveOrganism(payload) {
    const response = await Api().post(`api/dashboard/masters/organism/save`, payload);
    return response;
  },

  async deleteOrganism(payload) {
    const response = await Api().post(`api/dashboard/masters/organism/status`, payload);
    return response;
  },
  async importOrganism(payload) {
    const response = await Api().post(`api/dashboard/masters/organism/import`, payload);
    return response;
  },
  // Organism APIs End

  // Antibiotic Result Type APIs Start
  async getAllAntibioticResultTypes() {
    const response = await Api().get(`api/dashboard/masters/antibiotic-rt/list`);
    return response;
  },

  async saveAntibioticResultType(payload) {
    const response = await Api().post(`api/dashboard/masters/antibiotic-rt/save`, payload);
    return response;
  },

  async deleteAntibioticResultType(payload) {
    const response = await Api().post(`api/dashboard/masters/antibiotic-rt/status`, payload);
    return response;
  },
  // Antibiotic Result Type APIs End

  // Sample Status Start
  async getAllSampleStatuses() {
    const response = await Api().get(`api/dashboard/masters/sample-status/list`);
    return response;
  },
  // Sample Status End

};