import momentTz from 'moment-timezone';
import { read, utils, writeFile } from 'xlsx';
import { timeZone } from './constant';
import moment from 'moment';
import { handleLogout } from "../redux/auth/actions.js";

// const navigate = useNavigate();


export const exportToExcel = (data, headings, fileName, sheetName) => {
  // Create a new workbook
  const workbook = utils.book_new();
  const worksheet = utils.json_to_sheet([]);
  utils.sheet_add_aoa(worksheet, headings);
  utils.sheet_add_json(worksheet, data, { origin: 'A2', skipHeader: true });
  utils.book_append_sheet(workbook, worksheet, 'Report');
  writeFile(workbook, fileName);
};

export const calculateAge = (dateOfBirth) => {
  // console.log("dateOfBirth=>", dateOfBirth)
  const dob = new Date(dateOfBirth);
  const now = new Date();

  const diff = now.getTime() - dob.getTime();
  // console.log("diff=>", diff)
  const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));

  return age;
}

// Function to remove HTML tags using regex
export const removeTagsFromString = (htmlString) => {
  const regex = /(<([^>]+)>)/ig;
  return `${htmlString}`.replace(regex, '');
};

export const convertToAnotherTimezone = (date) => {
  /* var fmt = "MM/DD/YYYY HH:mm:ss a";  // must match the input
  var zone = timeZone;
  var m = momentTz.tz(date, zone).format(fmt); */
  return date; //moment(date).format("DD-MM-YYYY"); // AKC ne just date re-pass kiya hai
}


export const convertUTStoIST = (date) => {
  var fmt = "MM/DD/YYYY HH:mm:ss a A";  // must match the input
  // console.log(momentTz(date).format(fmt), "India")
  const parsedDate = moment(date);
  // console.log(parsedDate)
  var local = date;
  if (parsedDate.isValid()) {
    const utcMoment = momentTz.utc(parsedDate);
    // Convert UTC to IST (Indian Standard Time)
    local = utcMoment.add(6, 'hours').add(0, 'minutes');


  }

  return local
}

export const getParams = (payload) => {
  let param = 'All';
    if (typeof payload === 'object' && !Array.isArray(payload) && payload !== null) {
      const urlParams = new URLSearchParams(payload);
      param= urlParams.toString();
      console.log("Payload is an object", urlParams, param);
    } else if (typeof payload === 'string') {
      param= `search=${payload}`;
    } else {
      console.log("Payload is neither a plain object nor a string");
      param= `search=All`; // or throw an error
    }
    return param;
}



// src/utils/inactivityTimer.js

let inactivityTimeout;


export function setupInactivityTimer(dispatch, navigate) {
  const timeLimit = 43200000; // Equals to 12 hrs
  // console.log(inactivityTimeout);
  function resetTimer() {
    // console.log("Resetting inactivity timer.");
    clearTimeout(inactivityTimeout);

    inactivityTimeout = setTimeout(() => {
      console.log("Logging out due to inactivity.");
      dispatch(handleLogout(() => navigate('/login')));
    }, timeLimit);
  }

  window.onload = resetTimer;
  document.onmousemove = resetTimer;
  document.onkeypress = resetTimer;
  document.ontouchstart = resetTimer;
  document.onclick = resetTimer;
  document.onscroll = resetTimer;
}


export function clearInactivityTimer() {
  console.log("LOGOUT")
  clearTimeout(inactivityTimeout);
}
