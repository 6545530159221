import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import React, { useState, useEffect } from 'react'



function ReceptionSchedule({ open, onClose }) {
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedDepartments, setSelectedDepartments] = useState([])
  const [availabilityData, setAvailabilityData] = useState([])
  const [nurseDetails, setNurseDetails] = useState(null)
  const [detailsModalOpen, setDetailsModalOpen] = useState(false)


  const departmentOptions = [
    { value: 'Nursing', label: 'Nursing' },
    { value: 'Cardiology', label: 'Cardiology' },
    { value: 'Pediatrics', label: 'Pediatrics' },
  ]

  useEffect(() => {
    if (selectedDate && selectedDepartments.length > 0) {
      fetchAvailabilityData(selectedDate, selectedDepartments)
    } else {
      setAvailabilityData([])
    }
  }, [selectedDate, selectedDepartments])

  const fetchAvailabilityData = (date, departments) => {
    const mockData = [
      {
        time: '08:00 AM - 10:00 AM',
        nurses: [
          { name: 'Nurse 1', available: true, patientName: 'John Doe', availableSlot: '08:00 AM - 10:00 AM', reservationId: '12345' },
          { name: 'Nurse 2', available: false, patientName: 'Jane Smith', availableSlot: '09:00 AM - 11:00 AM', reservationId: '12346' },
          { name: 'Nurse 3', available: true, patientName: 'Alice Brown', availableSlot: '10:00 AM - 12:00 PM', reservationId: '12347' },
        ],
      },
      {
        time: '10:00 AM - 11:00 AM',
        nurses: [
          { name: 'Nurse 4', available: false, patientName: 'John Doe', availableSlot: '08:00 AM - 10:00 AM', reservationId: '12349' },
          { name: 'Nurse 5', available: true, patientName: 'Jane Smith', availableSlot: '09:00 AM - 11:00 AM', reservationId: '12348' },
          { name: 'Nurse 6', available: false, patientName: 'Alice Brown', availableSlot: '10:00 AM - 12:00 PM', reservationId: '12345' },
        ],
      },
      {
        time: '11:00 AM - 12:00 PM',
        nurses: [
          { name: 'Nurse 7', available: true, patientName: 'John Doe', availableSlot: '08:00 AM - 10:00 AM', reservationId: '1236745' },
          { name: 'Nurse 8', available: false, patientName: 'Jane Smith', availableSlot: '09:00 AM - 11:00 AM', reservationId: '1287346' },
          { name: 'Nurse 9', available: true, patientName: 'Alice Brown', availableSlot: '10:00 AM - 12:00 PM', reservationId: '1239047' },
        ],
      },
      // More time slots as needed
    ]

    setAvailabilityData(mockData)
  }

  const openNurseDetails = (nurse) => {
    setNurseDetails(nurse)
    setDetailsModalOpen(true)
  }

  const closeNurseDetails = () => {
    setNurseDetails(null)
    setDetailsModalOpen(false)
  }

  return (
    <Dialog open={open} onClose={onClose} className="relative z-30">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-5xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 min-h-[500px]">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left flex gap-5 mx-auto">
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">Select Date</label>
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      isClearable
                      className="mt-2 w-full rounded-md border-gray-300 shadow-sm"
                      placeholderText="Pick a Date"
                    />
                  </div>
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">Select Departments</label>
                    <Select
                      isMulti
                      options={departmentOptions}
                      value={selectedDepartments}
                      onChange={(options) => setSelectedDepartments(options)}
                      className="mt-2"
                      placeholder="Select departments"
                    />
                  </div>
                </div>
              </div>

              {availabilityData.length > 0 && (
                <div className="mt-6">
                  <h3 className="text-lg font-semibold text-gray-700">Nurse Availability</h3>
                  <table className="min-w-full mt-2 border rounded-lg">
                    <thead>
                      <tr>
                        <th className="px-4 py-2 text-left bg-gray-100">Time Slot</th>
                        <th className="px-4 py-2 text-left bg-gray-100">Nurse</th>
                        <th className="px-4 py-2 text-left bg-gray-100">Availability</th>
                        <th className="px-4 py-2 text-left bg-gray-100">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {availabilityData.map((slot, index) => (
                        <React.Fragment key={index}>
                          {slot.nurses.map((nurse, nurseIndex) => (
                            <tr key={nurseIndex} className="border-t">
                              {nurseIndex === 0 && (
                                <td rowSpan={slot.nurses.length} className="px-4 py-2 align-top">
                                  {slot.time}
                                </td>
                              )}
                              <td className="px-4 py-2">{nurse.name}</td>
                              <td className="px-4 py-2">
                                {nurse.available ? 'Available' : 'Not Available'}
                              </td>
                              <td className="px-4 py-2">
                                {nurse.available && (
                                  <button onClick={() => openNurseDetails(nurse)} className="text-blue-500 underline hover:text-blue-700">
                                    Nurse Details
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                onClick={onClose}
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
              >
                Close
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
        {/* Nurse Details Modal */}
        {detailsModalOpen && (
        <Dialog open={detailsModalOpen} onClose={closeNurseDetails} className="relative z-40">
          <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          <div className="fixed inset-0 flex items-center justify-center">
            <DialogPanel className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
              <h2 className="text-xl font-semibold text-gray-700">Nurse Details</h2>
              <p className="mt-4">Name: {nurseDetails.name}</p>
              <p className="mt-4">Patient Name: {nurseDetails.patientName}</p>
              <p className="mt-4">Nurse Available Slot: {nurseDetails.availableSlot}</p>
              <p className="mt-4">Reservation Id: {nurseDetails.reservationId}</p>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:px-6 justify-between my-5">
              <button onClick={closeNurseDetails} className="inline-flex float-left w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">
                Save
              </button>
              <button onClick={closeNurseDetails} className="inline-flex float-right w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">
                Cancel
              </button>
              </div>
              
            </DialogPanel>
          </div>
        </Dialog>
      )}
    </Dialog>
  )
}

export default ReceptionSchedule
