import React, { useEffect, useState, Fragment } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import CustomButton from "../../../components/common/CustomButton";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useFormik } from "formik";
import { shiftMasterSchema } from "../../../schemas";

const time = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

const ShiftMasterList = () => {
  const pages = [{ title: "Shift Master", href: "/shift", module_id: 50 }];
  const initialValues = {
    shift_name: "",
    start_date: "",
    end_date: "",
  };

  const [formShiftMaster, setFormShiftMaster] = useState(initialValues);
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);

  const handleDrawer = (type, id, obj) => {
    if ((type === "edit", id)) {
      //   setCityid(id);
      //   const initialValues = {
      //     city_id: obj.city_id,
      //     city_name_en: obj.city_name_en,
      //     city_name_ar: obj.city_name_ar,
      //     fk_state_id: obj.m_state_relation.state_id,
      //   };
      //   setFormCity(initialValues);
    } else {
      //   setCityid("");
      //   setFormCity(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      initialValues: formShiftMaster,
      validationSchema: shiftMasterSchema,
      onSubmit: (values, action) => {
        console.log("Submit Clicked", values);
      },
    });

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">City</h1>
        <p className="mt-2 text-sm text-gray-700">A list of all the Cities.</p>
      </div>
      <div className="mt-4 flex items-center justify-end gap-3">
        <CustomButton onClick={() => handleDrawer("add", "", {})}>
          Add Shift Master
        </CustomButton>
      </div>

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-cyan-700 py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {/* {city_id ? "Update" : "Add"} */}
                                Add Shift Master
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="p-4 sm:p-6">
                              <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                <div>
                                  <label
                                    htmlFor="name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Name
                                  </label>
                                </div>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.shift_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Shift Master Name"
                                    name="shift_name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.shift_name && touched.shift_name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.shift_name}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                <div>
                                  <label
                                    htmlFor="start_date"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Start Date
                                  </label>
                                </div>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                  <select
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                    name="start_date"
                                    id="start_date"
                                    value={values.start_date}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        handleChange(e); 
                                        setFieldValue("end_date", ""); 
                                      }}
                                
                                    onBlur={handleBlur}
                                  >
                                    <option value={null} disabled>
                                      {" "}
                                      Select Start Time{" "}
                                    </option>
                                    {time?.map((t, i) => (
                                      <option key={i} value={t}>
                                        {t}
                                      </option>
                                    ))}
                                  </select>
                                  {errors.start_date && touched.start_date ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.start_date}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                <div>
                                  <label
                                    htmlFor="end_date"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    End Date
                                  </label>
                                </div>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                  <select
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                    name="end_date"
                                    id="end_date"
                                    value={values.end_date}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option value={null} disabled>
                                      {" "}
                                      Select Start Time{" "}
                                    </option>
                                    {time?.map((t, i) => (
                                      <option key={i} value={t}>
                                        {t}
                                      </option>
                                    ))}
                                  </select>
                                  {errors.end_date && touched.end_date ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.end_date}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            {/* {shift_id ? "Update" : "Add"} */}
                            Add
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
};

export default ShiftMasterList;
